import React from "react"
import SEO from "../components/Seo"
import { mq } from "../layouts/styles/theme"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import ImgDomino from "../components/ImgDomino"
import { css } from "@emotion/core"
import { FigureContainer, Figure } from "../components/Figure"
import ueberMich_bw from "../images/belt/ueberMich_bw.jpg"
import ueberMich from "../images/belt/ueberMich.jpg"
import ueberMichIllustration from "../images/illustrations/ueberMichIllustration.jpg"
import img1 from "../images/predictive/predictivePraxis.jpg"
import img2 from "../images/predictive/cleliaWelcome.jpg"
import img3 from "../images/predictive/prafulVijayakar.jpg"
import img4 from "../images/predictive/clelia.jpg"
import img5 from "../images/predictive/predictiveTeam.jpg"
import white from "../images/predictive/white.jpg"

const UeberMichPage = () => (
  <>
    <SEO
      title="Über mich"
      keywords={[
        "Über mich",
        "Berufliche Werdegang",
        "Ausbildung, Weiterbildung",
        "Predictive Homeopathy",
        "Prafull Vijayakar",
      ]}
      description="Die Homöoopathie hat mir die ganzheitliche Betrachtung der Dinge geschenkt. Ich habe Molekularbiologie studiert und dann zusätzlich die Ausbildung in klassische Homöopathie, Schulmedizin und Resource Therapy abgeschlossen. Ich arbeite nach predictive homeopathy, welche von Prafull Vijayakar gegründet wurde. Sie basiert auf moderne Erkenntnisse der Medizin kombieniert mit der Homöopathie."
    />
    <Belt leftImage={ueberMich_bw} rightImage={ueberMich} />
    <Container>
      <Quote cite="Heinrich Heine">
        In uns selbst liegen die Sterne unseres Glücks.
      </Quote>
      <StaticBlockContainer>
        <StaticBlock>
          <Title>ÜBER MICH</Title>
          <p>
            Schon als Kind war ich ein sehr neugieriger Mensch und fasziniert
            vom Ursprung des Lebens, des Menschen und der Natur. Im Studium der
            Biologie konnte ich meiner Neugierde nachgehen und habe gelernt, die
            Zusammenhänge zu erforschen, Vernetzungen zu erkennen und
            gleichzeitig das grosse Bild nicht von den Augen zu verlieren. Mit
            der Homöopathie habe ich dann die ganzheitliche Sicht kennengelernt
            und sie als kraftvolle und natürliche Heilmethode entdeckt. Eine
            Heilmethode, die es ermöglicht, der Person als Ganzes zu begegnen
            und nicht den einzelnen Krankheiten getrennt.
          </p>
          <FigureContainer>
            <Figure src={ueberMichIllustration} alt="Über mich" />
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>BERUFLICHER WERDEGANG</Title>
          <table>
            <colgroup>
              <col
                css={css`
                  width: 65px;
                  ${mq[1]} {
                    width: 120px;
                  }
                `}
              />
              <col
                css={css`
                  width: auto;
                `}
              />
            </colgroup>
            <tbody>
              <tr>
                <td>Seit 2013</td>
                <td>Eigene Praxis für klassische Homöopathie</td>
              </tr>
              <tr>
                <td>2009&nbsp;- 2017</td>
                <td>
                  Berufliche Tätigkeit am Universitätsspital Zürich (im Bereich
                  der klinischen Studien, in der Klink für Hämatologie)
                </td>
              </tr>
              <tr>
                <td>2005&nbsp;- 2009</td>
                <td>
                  Berufliche Tätigkeit in der Pharmaindustire (im Bereich der
                  klinischen Studien, im Bereich der Onkologie und Nephrologie)
                </td>
              </tr>
            </tbody>
          </table>
        </StaticBlock>
        <StaticBlock>
          <Title>AUSBILDUNG</Title>
          <table>
            <colgroup>
              <col
                css={css`
                  width: 65px;
                  ${mq[1]} {
                    width: 120px;
                  }
                `}
              />
              <col
                css={css`
                  width: auto;
                `}
              />
            </colgroup>
            <tbody>
              <tr>
                <td>2019</td>
                <td>
                  Resource Therapy Ausbildung bei Prof. Gordon Emmerson in
                  Zürich
                </td>
              </tr>
              <tr>
                <td>2010&nbsp;- 2012</td>
                <td>Schulmedizinische Ausbildung an der Paramed in Baar</td>
              </tr>
              <tr>
                <td>2008&nbsp;- 2011</td>
                <td>
                  Ausbildung in klassischer Homöopathie an der SkHZ in Zürich
                  (dipl. Homöopathin SkHZ)
                </td>
              </tr>
              <tr>
                <td>2000&nbsp;- 2004</td>
                <td>
                  Studium der Molekularbiologie an der Universität Basel (dipl.
                  phil. nat. II)
                </td>
              </tr>
            </tbody>
          </table>
        </StaticBlock>
        <StaticBlock>
          <Title>WEITERBILDUNGEN</Title>
          <table>
            <colgroup>
              <col
                css={css`
                  width: 65px;
                  ${mq[1]} {
                    width: 120px;
                  }
                `}
              />
              <col
                css={css`
                  width: auto;
                `}
              />
            </colgroup>
            <tbody>
              <tr>
                <td>2019</td>
                <td>
                  Predictive Homeopathy - Seminar - Dr. A. Vijayakar -
                  Regensburg
                </td>
              </tr>
              <tr>
                <td>2017</td>
                <td>
                  Predictive Homeopathy - Seminar - Dr. P. und A. Vijayakar -
                  Regensburg
                  <br />
                  Predictie Homeopathy - Workshop (2 Wochen) - Dr. P. und A.
                  Vijayakar - Theorie und live Fälle - Mumbai (Indien)
                </td>
              </tr>
              <tr>
                <td>2015</td>
                <td>
                  Predictie Homeopathy - Workshop (2 Wochen) - Dr. P. und A.
                  Vijayakar - Theorie und live Fälle - Mumbai (Indien)
                </td>
              </tr>
              <tr>
                <td>2014</td>
                <td>
                  Predictie Homeopathy - Seminar - Dr. Vijayakar - Regensburg
                </td>
              </tr>
              <tr>
                <td>2013</td>
                <td>
                  Predictie Homeopathy - Seminar - Dr. Ambrish - Regensburg
                </td>
              </tr>
              <tr>
                <td>2012</td>
                <td>
                  Praktikum (5 Wochen) - Predictive Homeopathy - In der Praxis
                  von Dr. P. und A. Vijayakar - Mumbai (Indien)
                  <br />
                  Praktikum (3 Wochen) - Universitätsspital Zürich - In der
                  Abteilung Innere Medizin und auf der Notfall Station
                </td>
              </tr>
              <tr>
                <td>2008/2009</td>
                <td>
                  Hospitation (je 1 Woche) - Dr. Spinedi und Dr. Malchow -
                  Clinica Santa Croce - Orselina (TI)
                </td>
              </tr>
            </tbody>
          </table>
        </StaticBlock>
        <StaticBlock>
          <Title>PREDICTIVE HOMEOPATHY</Title>
          <p>
            Dr. Prafull Vijayakar praktiziert seit mehr als 40 Jahren klassische
            Homöopathie in seiner Praxis in Mumbai, Indien und hat die
            Predictive Homeopathy (voraussagbare Homöopathie) gegründet. Seine
            grösste Errungenschaft ist, dass er die Prinzipien der klassischen
            Homöopathie von Hahnemann mit den Erkentnissen der modernen Medizin
            verknüpft hat. Er hat eine holistische Sicht des Menschen und sagt,
            dass man nicht die Krankheiten im Menschen, sondern den Menschen in
            der Krankheit heilen soll.
          </p>
          <p>
            Nach meiner Ausbildung als Homöopathin habe ich ein fünfwöchiges
            Praktikum in der Klinik von Dr. Prafull Vijayakar und seinem Sohn
            Ambrish Vijayakar in Mumbai, Indien absolviert. Ich war von dem
            tiefen Fachwissen, der hervorragenden Arbeit, sowie den
            herausragenden klinischen Resultaten, mit sehr positiven Verläufen,
            begeistert. Ich hatte auch die Gelegenheit bei den "Hope for
            Hopeless" Camps dabei zu sein. Die Camps werden alle drei Monate
            veranstaltet und das Homöopathen-Team arbeitet auf freiwilliger
            Basis, damit die Behandlung für die Patienten kostenlos ist. Es war
            beeindruckend zu sehen wie an einem Tag mehr als 2’000 Patienten
            behandelt wurden und was für tolle Ergebnisse Dr. Vijayakar und sein
            Team dank der Homöopathie erzielten.
          </p>
          <p>
            Ich besuche fortlaufend Predictive Homeopathy Weiterbildungen in
            Indien und Regensburg und freue mich jedesmal, mich von neuem von
            den Vijayakars und ihren neusten Erkenntnissen inspirieren zu
            lassen.
          </p>
          <p>
            Mehr zu Predictive Homeopathy finden Sie unter{" "}
            <a
              href="https://www.predictivehomoeopathy.com/home"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.predictivehomoeopathy.com
            </a>
            &nbsp;, &nbsp;
            <a
              href="http://www.predictive.ch"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.predictive.ch
            </a>
            &nbsp; und &nbsp;
            <a
              href="https://www.predictive-homoeopathie.de"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.predictive-homoeopathie.de
            </a>
            .
          </p>
        </StaticBlock>
      </StaticBlockContainer>
      <ImgDomino
        images={[img1, img2, white, img3, white, img4, img5, white]}
        altTexts={[
          "Predictive Praxis",
          "Clelia Sasselli",
          "weisses Bild",
          "Praful Vijayakar",
          "weisses Bild",
          "Clelia Sasselli",
          "Team Predictive Homeopathy",
          "weisses Bild",
        ]}
      />
    </Container>
  </>
)

export default UeberMichPage
